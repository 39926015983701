import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Toggle from '../../components/ui/Toggle';

const IndexPage = () => (
  <Layout>
    <SEO title="Shipping & Returns" />
    <h2>Shipping and Delivery</h2>
    <p>With Leem, we want to ensure that your shopping experience with us is always easy and convenient, so here is everything you need to
      know about getting your order delivered.</p>
    <Toggle title="Where do you ship in the KSA?">
      <p>We ship across KSA</p>
    </Toggle>
    <Toggle title="What type of shipping service do you offer and how much does it cost?">
      <p>We offer free delivery on all standard delivery orders.</p>
      <p>Premium Services such as Same Day or Next Day are charged</p>
      <table>
        <tr>
          <td>Standard Delivery</td>
          <td>Next Day Delivery</td>
          <td>Same Day Delivery</td>
        </tr>
        <tr>
          <td>Free</td>
          <td>SAR 20</td>
          <td>SAR 50</td>
        </tr>
      </table>
    </Toggle>
    <Toggle title="Which courier company has Leem partnered with?">
      <p>At Leem our goal is to ensure you receive your orders as efficiently as possible. That is why we’ve teamed up with some of the best
        courier companies in the world. Your order will be delivered by either DHL or SMSA </p>
    </Toggle>
    <Toggle title="When will I receive my order?">
      <p>Your order will usually be delivered within 1-3 business days starting from the day you place your order.</p>
      <p>If you have chosen a premium delivery service, your order will be shipped to ensure you receive your products when you expected
        them.</p>
    </Toggle>
    <Toggle title="How will I know when my order is on its way to me?">
      <p>We will send you an email when your order has been shipped. Our courier partner will also give you a call to arrange the most
        suitable time to deliver your order.</p>
    </Toggle>
    <Toggle title="Can I change my shipping address after placing an order?">
      <p>Yes, you can, just inform our partner courier company or give us a call prior to shipping of your order.</p>
    </Toggle>
    <Toggle title="Do I have to present anything at the time of shipping?">
      <p>We might ask you for a form of ID such as National Identity Card or your passport</p>
    </Toggle>
    <h2>Returns Policy</h2>
    <p>We hope you enjoy your new item(s) from Leem. Should you change your mind here is what you need to know about returning and refunding
      your Leem purchase.</p>
    <Toggle title="How long do I have to return an item(s)?">
      <p>From the time your order is delivered or collected, you will have 15 days to return your purchase, as long as it meets our return
        policy </p>
    </Toggle>
    <Toggle title="What is your return policy?">
      <p>We will be happy to refund any item as long as it is accompanied by your tax invoice and returns label </p>
      <p>The returned item must be in a pristine and unused condition, in its original packaging and with the tags still on.</p>
      <p>All refunds will be credited back via your original payment method</p>
    </Toggle>
    <Toggle title="Are there any items excluded?">
      <p>Yes, items excluded include pierced jewellery and cosmetic items </p>
    </Toggle>
    <Toggle title="How do I return my item(s)?">
      <p>We have made it as easy as possible for you to return your item(s). You can either return it to any of our stores within Saudi
        Arabia
        or use the returns label and arrange a pickup of your item(s) from our partner couriers. </p>
      <p>To return via courier, please use the pre-paid label provided in your delivery.</p>
      <p>Contact the courier to arrange pick up</p>
      <p>Complete the returns label that came with your package, indicating the reason for return. Enclosing this will help us to process
        your
        return.</p>
      <p>Hand over your return to the courier.</p>
      <p>You will receive a confirmation e-mail as soon as your return has been received </p>
    </Toggle>
    <Toggle title="What if I have paid through my debit or credit card?">
      <p>The whole process can take up to 3 weeks starting the day we receive your return item(s). You will receive a notification when we
        have processed your return and the value will be credited back to your original payment card.</p>
    </Toggle>
    <Toggle title="I paid cash on delivery; how do I get my refund?">
      <p>There are 2 ways you can get a refund for your cash on delivery order. You can either get the amount credited to your bank account
        by
        filling in your bank account details in the refund form we sent you via e-mail, or get a cash refund by returning the item(s) to one
        of our stores In Saudi Arabia </p>
    </Toggle>
    <Toggle title="Will I get my VAT refunded?">
      <p>Yes, we will refund your item(s) including VAT </p>
    </Toggle>
    <Toggle title="Will I get a refund for the delivery cost?">
      <p>You will not be able to get a refund for your shipping fee in most cases. For more information on this please contact us on 800 440 0099, or send us an email at customercare@leem.com </p>
    </Toggle>
    <Toggle title="Something has gone wrong?">
      <p>If you think you have received the wrong amount or have not received your refund within the expected timescale, please get in touch
        with us and we will be happy to help</p>
    </Toggle>
  </Layout>
);

export default IndexPage
